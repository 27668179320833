<template>
    <v-container fluid style="margin-bottom: 100px">
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
            <v-col cols="12">
              <v-card class="rounded-l pb-5 pt-5 " style="border-top: 3px solid #1565C0;">
                    <v-card-title class="pb-1 pt-1">
                        Filter
                    </v-card-title>
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete
                                    :disabled="nonHO"
                                    solo
                                    clearable
                                    v-model="office"
                                    :items="offices"
                                    item-value="office_id"
                                    item-text="office"
                                    label="Area"
                                    @change="(event) => updateSales(event)"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete
                                    :disabled="nonHO"
                                    solo
                                    clearable
                                    v-model="sales"
                                    :items="saleses"
                                    item-value="sales_id"
                                    item-text="nama"
                                    label="Sales"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-autocomplete>
                                <v-progress-linear
                                    :active="loading2"
                                    :indeterminate="loading2"
                                    absolute
                                    bottom
                                    color="deep-purple accent-4"
                                ></v-progress-linear>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu ref="modal" v-model="modal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attr }">
                                    <v-text-field solo v-model="date_from" label="Date from" prepend-icon="mdi-calendar" readonly class="ma-0 pa-0" hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_from" no-title @input="modal = false"></v-date-picker>
                                 </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu ref="modal_to" v-model="modal_to" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">   
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field solo v-model="date_to" label="Date to" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="ma-0 pa-0" hide-details=true></v-text-field>
                                    </template>
                                  <v-date-picker v-model="date_to" no-title @input="modal_to = false"></v-date-picker>
                                     </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-btn
                                    class="mr-2 mt-1"
                                    color="info"
                                    elevation="2"
                                    large
                                    @click="search()"
                                >Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" style="padding-bottom: 100px">
                <v-card style="border-top: 3px solid #1565C0;">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>
                                    Result
                                    <v-spacer></v-spacer>
                                    <!-- <v-btn
                                        class="mr-2 mt-1"
                                        color="info"
                                        elevation="2"
                                        rounded
                                        @click="search()"
                                    >Export</v-btn> -->
                                    <v-btn
                                        class="mr-2 mt-1"
                                        color="primary"
                                        elevation="2"
                                        rounded
                                        :disabled="disabledExport">
                                        <download-excel
                                             class="text"
                                            :fetch           = "exportExcel"
                                            :fields="headersColumn"
                                            :before-generate = "startDownload"
                                            :before-finish   = "finishDownload">
                                            Export Excel
                                        </download-excel>    
                                    </v-btn>
                                </v-card-title>
                                
                                <v-data-table
                                    :headers="headers"
                                    :items="purchases"
                                    :options.sync="options"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItems"
                                    :page.sync="pagination"
                                    page-count="10"
                                    class="elevation-1"
                                >

                                    <template v-slot:[`header.tr_id`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>

                                    <template v-slot:[`header.customerid`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.namacustomer`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.salesid`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.office`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.tr_date`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                 
                                    <template v-slot:[`item.actions`]="{ item }">
                                                <v-tooltip top> 
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon small class="mr-1" @click="showDialogAlbum(item)" v-on="on">
                                                            mdi-image
                                                        </v-icon>
                                                    </template>
                                                    <span>View Photo</span>
                                                </v-tooltip>
                                                <v-tooltip top> 
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon small class="mr-1" @click="showItem(item)" v-on="on">
                                                            mdi-eye
                                                        </v-icon>
                                                    </template>
                                                    <span>View Map</span>
                                                </v-tooltip>
                                                <v-tooltip top v-if="allowDelete=='11'"> 
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon 
                                                            small @click="showDeleteDialog(item)" v-on="on">
                                                            mdi-delete
                                                        </v-icon>
                                                    </template>
                                                    <span>Delete Activity</span>
                                                </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>

                <v-dialog 
                    v-model="dialogAlbum"
                    max-width="1000px">
                    <v-card>
                        <v-toolbar
                            color="primary text-white"
                        >
                        <span  style="color:white">
                            Uploaded Photos
                        </span>
                        </v-toolbar>
                        
                        <v-card-text class="mt-3">
                            <v-carousel
                                progress
                                cycle
                                interval="10000"
                                show-arrows-on-hover
                            >
                                <v-carousel-item
                                    v-for="(pictureDatas,i) in pictureDatas"
                                    :key="i"
                                    :src="pictureDatas.src"
                                    lazy-src="https://picsum.photos/id/11/100/60"
                                    reverse-transition="fade-transition"
                                    transition="fade-transition"
                                    contain
                                >
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                            ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-carousel-item>
                            </v-carousel>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDialogAlbum">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="dialog" max-width="1000px">                
                    <v-card>
                        <v-toolbar
                            color="primary text-white"
                        >Detail Location
                        </v-toolbar>
                        <v-card-text class="mt-3">
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <div id="map_canvas" style="height:432px; width:100%; border: 1px solid grey;"></div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="delete_dialog" max-width="300px">                
                    <v-card>
                        <v-toolbar
                            class="text-h5"
                            color="green lighten-1"
                        >Hapus Data
                        </v-toolbar>
                        <v-card-text class="mt-3">
                                Anda yakin untuk aktivitas data ini?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteData">Delete</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
      </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Tobang',
                disabled: false,
                href: '/admin/tobang',
                },
                {
                text: 'Mitra Recruiter',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Activity Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            offices: [],
            office: '',
            saleses: [],
            sales: '',
            date_from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            date_to: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal_to: false,
            display: 'none',
            purchases: [],
            headers: [
                { text: 'Mill Id', align: 'start',
                    value: 'mill_id', sortable: false  },
                { text: 'Tr Id', value: 'tr_id', sortable: false  },
                { text: 'New', value: 'new', sortable: false  },
                { text: 'Id Toko', value: 'customerid', sortable: false  },
                { text: 'Nama Toko', value: 'namacustomer', sortable: false  },
                { text: 'Alamat Toko', value: 'alamat', sortable: false  },
                { text: 'Sales Id', value: 'salesid', sortable: false  },
                { text: 'Ref Code', value: 'ref_code', sortable: false  },
                { text: 'Area', value: 'office', sortable: false  },
                { text: 'Tanggal', value: 'tr_date', sortable: false  },
                { text: 'Total File', value: 'total_file', sortable: false  },
                { text: 'Remark', value: 'remark', sortable: false  },
                { text: 'Actions',  value: 'actions', sortable: false, width:'7%' },
            ],
            headersColumn: {
                'Id Toko': 'customerid',
                'Nama Toko': 'namacustomer',
                'Alamat Toko': 'alamat',
                'Sales Id': 'salesid',
                'Ref Code': 'ref_code',
                'Area': 'office',
                'Tanggal': 'tr_date',
                'Total File': 'total_file',
                'Remark': 'remark',
            },
            totalItems: 10,
            options: {},
            loading2: false,
            search_detail: '',
            deleted_data:{},
            item_data:{},
            po_items: [],
            po_item: '',
            option_po_items: {},
            loading3: false,
            loading4: false,
            loading5: false,
            totalItem_po_items: 10,
            dialog: false,
            delete_dialog:false,
            user_approve1: '',
            user_approve2: '',
            pagination: 1,
            mymap: '',
            disabledExport:false,
            allowDelete: '',
            sortBy: 'desc',
            sortName: '',
            dialogAlbum:false,
            nonHO:false,
            pictureDatas: [
                {
                    src: 'https://images.unsplash.com/photo-1636302304088-e1bd81885a71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80',
                },
            ],
        }
    },
    mounted(){
        this.getEnvConf()
        this.getSalesOffice()
        // this.getOffice()
    },
    methods:{
        getEnvConf(){
            let data = {
                entity_id : 'TOBANG',
                appl_id : 'DASHBOARD'
            }

            this.$store.dispatch("getVariabelUser", { data }).then(res => {
                for (let i = 0; i < res.data.data.length; i++) {
                    if (res.data.data[i]['var_id'] === 'DELACTIVITY') {
                        this.allowDelete = res.data.data[i]['var_value']
                    }
                }
            })
        },
        async getSalesOffice(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/tobang/recruiter?sales_id=${this.$store.state.user.username}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                if(res.data.data[0].office_id == 1){
                    this.nonHO = false;
                    this.getOffice();
                } else {
                    this.nonHO = true;
                    this.getOffice(res.data.data[0].office_id, res.data.data[0].sales_id);
                }
                
            })
        },
        async getOffice(officeid, salesid){
            var url = `${process.env.VUE_APP_URL}/api/master/tobang/piutang`
            if (!this.nonHO){
                url = `${process.env.VUE_APP_URL}/api/master/tobang/piutang`
            } else {
                url = `${process.env.VUE_APP_URL}/api/master/tobang/piutang?officeid=${officeid}&salesid=${salesid}`
            }
            await axios.get(url, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.offices = res.data.office
                this.saleses = res.data.sales
                if (this.nonHO){
                    this.office=this.offices[0].office_id
                    this.sales=this.saleses[0].sales_id
                }
            });
        },
        search(){
            this.pagination = 1
            this.getPullData(1, 10, true)
        },
        close() {
            this.dialog = false
        },
        closeDelete(){
            this.delete_dialog = false
        },
        showDialogAlbum(item){
            this.dialogAlbum = true;
            this.item_data = item;
            //get data disini
            this.pictureDatas = [];
            this.getImages();
        },
        closeDialogAlbum(){
            this.dialogAlbum = false;
            this.pictureDatas = [];
        },
        async getImages(){
            await axios.get(`${process.env.VUE_APP_URL}/api/tobang/sales_activity/get_source?mill_id=${this.item_data.mill_id ? this.item_data.mill_id : ""}&tr_id=${this.item_data.tr_id ? this.item_data.tr_id : ""}&salesid=${this.item_data.salesid ? this.item_data.salesid : ""}&customerid=${this.item_data.customerid ? this.item_data.customerid : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                if(res.data.data.length === 0){
                    this.pictureDatas = [
                        {
                            src: 'https://images.unsplash.com/photo-1636302304088-e1bd81885a71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80',
                        },
                    ]
                } else {
                    this.pictureDatas = res.data.data
                }
            })
        },
        async showItem(item){
            this.po_item = item
            this.dialog = true
            this.loading = true

            if (this.mymap != '') {
                this.mymap.off();
                this.mymap.remove();
            }

            await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${this.po_item.sales_latitude}&lon=${this.po_item.sales_longitude}`)
            .then(res => {
                this.loading = false
                var address = res.data.address.village +', '+ res.data.address.state_district +', '+ res.data.address.state;

                this.mymap = L.map('map_canvas').setView([this.po_item.sales_latitude, this.po_item.sales_longitude], 13);
        
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_TOBANG}`, {
                    maxZoom: 18,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap);

                L.marker([this.po_item.sales_latitude, this.po_item.sales_longitude],
                    {draggable: true,        // Make the icon dragable
                    //title: 'Hover Text',     // Add a title
                    opacity: 0.5}            // Adjust the opacity
                    )
                    .addTo(this.mymap)
                    .bindPopup('<b>Your Seller Visit location</b><br>'+address)
                    .openPopup();

            });
        },
        sortByFunc(sortBy){
            console.log(this.sortBy, sortBy);
            this.sortBy = this.sortBy === 'desc' ? 'asc' : 'desc';
            this.sortName = sortBy
            this.getPullData(1, 10)
        },

        async showItem(item){
            this.po_item = item
            this.dialog = true
            this.loading = true

            if (this.mymap != '') {
                this.mymap.off();
                this.mymap.remove();
            }

            await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${this.po_item.sales_latitude}&lon=${this.po_item.sales_longitude}`)
            .then(res => {
                this.loading = false
                var address = res.data.address.village +', '+ res.data.address.state_district +', '+ res.data.address.state;

                this.mymap = L.map('map_canvas').setView([this.po_item.sales_latitude, this.po_item.sales_longitude], 13);
        
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_TOBANG}`, {
                    maxZoom: 18,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap);

                L.marker([this.po_item.sales_latitude, this.po_item.sales_longitude],
                    {draggable: true,        // Make the icon dragable
                    //title: 'Hover Text',     // Add a title
                    opacity: 0.5}            // Adjust the opacity
                    )
                    .addTo(this.mymap)
                    .bindPopup('<b>Your Seller Visit location</b><br>'+address)
                    .openPopup();

            });
        },
        async getPullData(page = 1, itemsPerPage = 10, search){
            this.loading = true

            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            await axios.get(`${process.env.VUE_APP_URL}/api/tobang/sales_activity?search=${this.$store.state.text}&txtOffID=${this.office ? this.office : ""}&salesid=${this.sales ? this.sales : ""}&start_date=${start_date ? start_date : ""}&end_date=${end_date ? end_date : ""}&sort=${this.sortBy ? this.sortBy : 'desc'}&sortName=${this.sortName ? this.sortName : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                
                this.display = 'block'
                this.loading = false
                this.purchases = res.data.data
                this.totalItems = res.data.meta.total
                this.pagination = res.data.meta.current_page
            })
        },
        
        async updateSales(value){
            var url = (value != null) 
                ? `/api/master/tobang/piutang?officeid=${value}`
                : '/api/master/tobang/piutang'
            await axios.get(`${process.env.VUE_APP_URL}${url}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.saleses = res.data.sales
            });
        },
        async exportExcel(){
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var url = `${process.env.VUE_APP_URL}/api/tobang/sales_activity?search=${this.$store.state.text}&txtOffID=${this.office ? this.office : ""}&salesid=${this.sales ? this.sales : ""}&start_date=${start_date ? start_date : ""}&end_date=${end_date ? end_date : ""}`
            const response = await axios.get(url, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            });
            // console.log("exportExcel");
            return response.data.data;
        },

        startDownload(){
            // console.log("startDownload");
            this.loading = true
            this.disabledExport=true
        },
        finishDownload(){
            // console.log("finishDownload");
            this.loading = false
            this.disabledExport=false
        },

        showDeleteDialog(item){
            this.deleted_data = item
            this.delete_dialog = true
        },
        async deleteData(){
            var url = `/api/tobang/sales_activity/deleteData?mill_id=${this.deleted_data.mill_id}&tr_id=${this.deleted_data.tr_id}&customerid=${this.deleted_data.customerid}`
            await axios.get(`${process.env.VUE_APP_URL}${url}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                console.log(res.data);
                (res.data.errorCode == '00') 
                ?
                    this.snackbar = {
                        color: "info",
                        icon: "mdi-information-variant",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Info",
                        text: res.data.errorMessage,
                        visible: true
                    }
                : this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: res.data.errorMessage,
                        visible: true
                    }
            });
            this.delete_dialog = false
            this.getPullData()
        }
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                if (this.display === 'block') {
                    this.getPullData(page, itemsPerPage)
                }
            },
            deep: true,
        }
    }
}
</script>